<template>
  <div>
    <v-row>
      <v-col class="d-flex" cols="6">
        <app-text-field class="mr-4" label="Data" solo flat background-color="grey lighten-4"
          v-model="params.date_start" type="date" />
        <app-text-field class="mr-4" label="Data" solo flat background-color="grey lighten-4" v-model="params.date_end"
          type="date" />
        <v-btn class="mr-4" color="primary" @click="select()">Pesquisar</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Marca</th>
                <th class="text-right" style="width: 15%;">Estoque</th>
                <th class="text-right" style="width: 15%;">Compras</th>
                <th class="text-right" style="width: 15%;">Venda</th>
                <th class="text-right" style="width: 15%;">Lucro</th>
                <th class="text-right" style="width: 15%;">Lucro (%)</th>
                <th class="text-right" style="width: 15%;">Giro de Estoque (%)</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row) in brands.data">
                <tr>
                  <td>{{ row.name }}</td>
                  <td class="text-right" style="width: 15%;">{{ $format.decimal(row.stock_cost_stock_sum) }}</td>
                  <td class="text-right" style="width: 15%;">{{ $format.decimal(row.purchase_total_sum) }}</td>
                  <td class="text-right" style="width: 15%;">{{ $format.decimal(row.sale_net_price_cash_total_sum) }}
                  </td>
                  <td class="text-right" style="width: 15%;">{{ $format.decimal(row.sale_profit_sum) }}</td>
                  <td class="text-right" style="width: 15%;">{{ $format.decimal(calcProfitMargin(row)) }}</td>
                  <td class="text-right" style="width: 15%;">{{ $format.decimal(calcStockTurnover(row)) }}</td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination v-model="params.page" :length="brands.last_page" @input="select" :total-visible="9" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { format, differenceInDays } from "date-fns";

export default {
  data: () => ({
    showFilter: false,
    params: {
      date_start: format(new Date(), "yyyy-MM-01"),
      date_end: format(new Date(), "yyyy-MM-dd"),
      page: 1,
    },
    brands: {
      data: [],
      last_page: 1, // Adicione esta linha para controlar o número total de páginas
    }
  }),

  methods: {
    select(page = 1) {
      this.params.page = page;
      this.$loading.start();
      this.$http
        .index("stock/stock-movement-report-by-brand", this.params)
        .then((response) => {
          this.$loading.finish();
          this.brands = response.brands;
        });
    },

    handleExport() {
      // Export logic here
    },

    calcStockTurnover(row) {
      const dateStart = new Date(this.params.date_start);
      const dateEnd = new Date(this.params.date_end);

      const days = differenceInDays(dateEnd, dateStart) || 1; // Avoid division by zero

      const annualizedFactor = 365 / days;

      if (!row.stock_price_cash_stock_sum || row.stock_price_cash_stock_sum === 0) {
        return 0; // Avoid division by zero or undefined
      }

      return (row.sale_net_price_cash_total_sum / row.stock_price_cash_stock_sum) * annualizedFactor;
    },

    calcProfitMargin(row) {
      if (row.sale_net_price_cash_total_sum === 0) {
        return 0;
      }
      return (row.sale_profit_sum / row.sale_net_price_cash_total_sum) * 100;
    },
  },
};
</script>

<style></style>